var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.bookings,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize",attrs:{"label":"","color":item.getStatusColour()}},[_vm._v(_vm._s(_vm._f("sentence")(item.data.status)))])]}},{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient.data.name)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startTime,"EEEE',' do 'of' MMMM',' yyyy"))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startTime,'h:mma'))+" to "+_vm._s(_vm._f("formatDate")(item.endTime,'h:mma'))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.data.duration)+" minutes")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary","to":{ name: _vm.routeNames.practitioner.bookings.view, params: { uuid: item.uuid } }}},[_vm._v("View")]),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteBooking(item)}}},[_vm._v("Archive")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',[_c('span',[_c('strong',[_vm._v("Updated: ")]),_vm._v(" "+_vm._s(item.updatedAt))]),_c('br'),_c('span',[_c('strong',[_vm._v("Created: ")]),_vm._v(" "+_vm._s(item.createdAt))])])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }