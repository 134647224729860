























































import { Booking } from '@/models';
import { PropType, defineComponent } from '@/plugins/composition';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  name: 'BookingsTable',
  props: {
    bookings: {
      type: Array as PropType<Booking[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const headers = [
      { text: 'Status', value: 'status' },
      { text: 'Patient', value: 'patient' },

      { text: 'Date', value: 'date' },
      { text: 'Time', value: 'time' },
      { text: 'Duration', value: 'duration' },
      // { text: 'Practitioner', value: 'practitioner' },
      { text: 'Actions', value: 'actions', width: '1%', align: 'center', sortable: false },
    ];

    const deleteBooking = async (booking: Booking): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this booking?', {
        confirmType: 'warn',
      });

      if (confirm) {
        await booking.directDelete();

        emit('deleted');
      }
    };

    return { headers, routeNames, deleteBooking };
  },
});
