













import BookingsTable from './components/BookingsTable.vue';
import endOfDay from 'date-fns/fp/endOfDay/index';
import formatISO from 'date-fns/esm/fp/formatISO';
import startOfDay from 'date-fns/startOfDay/index';
import { Booking } from '@/models';
import { addDays } from 'date-fns';
import { computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { storage } from '@/main';

export default defineComponent({
  components: { BookingsTable },
  name: 'BookingListPage',
  setup() {
    const today = ref<Booking[]>([]);
    const tomorrow = ref<Booking[]>([]);
    const future = ref<Booking[]>([]);

    const practitioner = computed(() => storage.auth.asPractitioner);

    const todayRange = computed(() => ({
      start: formatISO(startOfDay(new Date())),
      end: formatISO(endOfDay(new Date())),
    }));

    const tomorrowRange = computed(() => ({
      start: formatISO(addDays(startOfDay(new Date()), 1)),
      end: formatISO(addDays(endOfDay(new Date()), 1)),
    }));

    const futureRange = computed(() => ({
      start: formatISO(addDays(endOfDay(new Date()), 1)),
    }));

    const loadTodaysBookings = async () => {
      today.value = await practitioner.value
        .bookings()
        .where('starts_between', [todayRange.value.start, todayRange.value.end])
        .getAll();
    };

    const loadTomorrowsBookings = async () => {
      tomorrow.value = await practitioner.value
        .bookings()
        .where('starts_between', [tomorrowRange.value.start, tomorrowRange.value.end])
        .getAll();
    };

    const loadFutureBookings = async () => {
      future.value = await practitioner.value.bookings().where('starts_after', futureRange.value.start).getAll();
    };

    onMounted(async () => {
      await Promise.all([loadTodaysBookings(), loadTomorrowsBookings(), loadFutureBookings()]);
    });

    return {
      practitioner,
      today,
      tomorrow,
      future,
      loadTodaysBookings,
      loadFutureBookings,
      loadTomorrowsBookings,
    };
  },
});
